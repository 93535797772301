import React, {useContext, useState} from "react"
import {graphql} from "gatsby"
import ls from "local-storage"
import {useMutation, useQuery} from "@apollo/client"
import {isBefore} from 'date-fns'

import {SEO} from "../components/common/SEO"
import Layout from "../components/common/Layout"
import GraphQLErrorList from "../components/common/GraphqQLErrorList"
import {saveCheckoutInLocalStorage} from "../services/cartService"
import {
  ADD_ATTRIBUTES,
  APPLY_DISCOUNT,
  CHECKOUT_BY_ID,
  CHECKOUT_CREATE,
  REMOVE_DISCOUNT,
  TOGGLE_CART,
} from "../graphql/queries"
import ModuleDealer from "../modules/ModuleDealer"
import {AppContext} from "../context";

const Dealer = props => {
  //***************This checkoutData has to be in parent of all the instance of moduleContentWithPropductCards */

  const [state, dispatch] = useContext(AppContext);
  const [addAttributes] = useMutation(ADD_ATTRIBUTES)
  const {tracking} = state;

  const [removeDiscount] = useMutation(REMOVE_DISCOUNT, {
    optimisticResponse: response => console.log(response),
    onError: (error => console.error(error))
  })
  const [applyDiscount] = useMutation(APPLY_DISCOUNT, {
    optimisticResponse: response => console.log(response),
    onError: (error => console.error(error))
  })
  const [cartItems, setCartItems] = useState(null);
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
      console.log('cart toggled', checkoutData);
    },
  })

  const {data: checkoutData, refetch: refechCheckoutData} = useQuery(
    CHECKOUT_BY_ID,
    {
      // skip: !ls("lastCheckoutID"),
      variables: {id: ls("lastCheckoutID")},
      optimisticResponse: response => console.log('dealer update cart', response),
      onCompleted: result => {
        const {id, orderStatusUrl} = result.node;

        if (tracking && !tracking.expired && tracking.expiresAt && isBefore(new Date(), new Date(tracking.expiresAt)) && !tracking.email && tracking.customerId && tracking.referCode && tracking.discountCode && !orderStatusUrl) {
          const input = {
            customAttributes: [{key: 'referCode', value: tracking.referCode}, {
              key: 'customerId',
              value: tracking.customerId
            }]
          };
          const checkoutId = id.toString();
          addAttributes({
            variables: {
              checkoutId,
              input
            }
          }).then(result => {
            console.log(result);
            const discountCode = tracking.discountCode;
            applyDiscount({
              variables: {
                checkoutId,
                discountCode
              }
            }).then(result => console.info(result))

          }).catch(e => console.error(e))

        } else if (orderStatusUrl) {
          ls.remove("lastCheckoutID");

        } else {
          const checkoutId = id.toString();
          removeDiscount({variables: {checkoutId}})
        }
      },

    }
  )

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      //window.history.pushState("header", "Not Pot", "/#cart")
      toggleCart({variables: {cartDrawerVisible: true}})
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      console.log(ls("lastCheckoutID"))
      refechCheckoutData({
        // skip: !ls("lastCheckoutID"),
        variables: {id: ls("lastCheckoutID")},
      })
    },
  })
  //********************************** */

  // useEmergence()
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    )
  }
  const {affiliate} = data
  const site = (data || {}).site
  const location = props.location

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const pageTitle = affiliate.name
  const media = affiliate?.header?.image?.asset?.url || ''
  return (
    <Layout site={site}>
      <SEO
        title={pageTitle}
        media={media}
      />
      <ModuleDealer affiliate={affiliate} checkoutData={cartItems}/>
    </Layout>
  )
}

export default Dealer

export const query = graphql`
  query DealerTemplateQuery($id: String!, $slug: String!) {
    affiliate: sanityAffiliatePage(id: { eq: $id }) {
      name
      quote
      quoteSource
      zodiac
      header
      slug
      AffiliateProducts {
        description
        affiliateProduct {
          title
          desktopTitle
          mobileTitle
          price
          inventory_quantity
          productHandle
          variantTitle
          featuredImage {
            asset {
              path
              url
            }
          }
          carousel {
            assets {
              video {
                asset {
                  url
                }
              }
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
      info {
        city
        state
      }
      social {
        twitter
        instagram
        youtube
        tiktok
      }
      preferences {
        interest
        music
        movies
        books
        munchies
      }
      head {
        image {
          asset {
            path
            url
          }
        }
        alt
      }
      _createdAt
    }
    junipProduct(remote_handle: { eq: $slug }) {
      id
      rating_average
      rating_count
      remote_id
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`
