import React from "react"
import { Link } from "gatsby"
import { Div, Text } from "atomize"
import { useWindowSize } from "react-use"

import ImageLazyLoad from "../common/molecules/ImageLazyLoad"
import {
  getVariantFeatureImage,
  getVariantHoverImage,
} from "../../utilities/helpers"

const TopPickCard = ({
  affiliatedProduct: { affiliateProduct, description },
  id,
}) => {
  const { width } = useWindowSize()
  const handleMouseEnter = () => {
    if (!getVariantHoverImage(affiliateProduct)) return
    const card = document.getElementById(`top-pick-img-${id}`)
    card.style["opacity"] = 0
    const hoverCard = document.getElementById(`top-pick-hover-${id}`)
    hoverCard.style["opacity"] = 1
  }
  const handleMouseLeave = () => {
    if (!getVariantHoverImage(affiliateProduct)) return
    const card = document.getElementById(`top-pick-img-${id}`)
    card.style["opacity"] = 1
    const hoverCard = document.getElementById(`top-pick-hover-${id}`)
    hoverCard.style["opacity"] = 0
  }

  return (
    <Div
      textSize="body"
      d="flex"
      flexDir="column"
      w="100%"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      cursor="pointer"
    >
      <Link
        to={`/products/${
          affiliateProduct.productHandle
        }/${affiliateProduct.variantTitle.toLowerCase().replace(/\s/g, "-")}`}
        style={{ color: "inherit" }}
      >
        <Div
          rounded="default"
          d="flex"
          overflow="hidden"
          m={{ b: "20px" }}
          pos="relative"
        >
          <ImageLazyLoad
            id={`top-pick-img-${id}`}
            bgImg={getVariantFeatureImage(affiliateProduct)}
            bgPos="center"
            bgSize="cover"
            p={{ b: "100%" }}
            w="100%"
            transition
          />

          <Div
            id={`top-pick-hover-${id}`}
            transition
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              opacity: 0,
            }}
          >
            <Div
              bgImg={getVariantHoverImage(affiliateProduct)}
              p={{ b: "100%" }}
              bgSize="cover"
              bgPos="center"
            />
          </Div>
        </Div>
        <Text m={{ b: "10px" }} textWeight="bold">
          {width > 420
            ? affiliateProduct.desktopTitle || affiliateProduct.title
            : affiliateProduct.mobileTitle ||
              affiliateProduct.desktopTitle ||
              affiliateProduct.title}
        </Text>
        <Text> &ldquo;{description}&rdquo;</Text>
      </Link>
    </Div>
  )
}

export default TopPickCard
