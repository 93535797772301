import React, {useContext, useEffect} from "react"
import {Div} from "atomize"
import {nanoid} from "nanoid"
import ls from "local-storage"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import PolaroidCard from "../components/dealer/PolaroidCard"
import DealerDetails from "../components/dealer/DealerDetails"
import DealerContact from "../components/dealer/DealerContact"
import TopPicks from "../components/dealer/TopPicks"
import {AppContext} from "../context"

import {trackingActions} from "../actions"
import {add} from "date-fns"

export default function ModuleDealer({affiliate, checkoutData}) {
  const [state, dispatch] = useContext(AppContext)
  const generateReferCode = (referCode, landing = "") => {
    const myHeaders = new Headers()
    myHeaders.append("Api-key", "bf43da12f9adfe45a43f923f877f2f17bf731426")
    myHeaders.append("Content-Type", "application/json")
    const trackingPayload = {
      referral_code: referCode,
      referrer: document.referrer,
      landing_page: landing,
      user_agent: navigator.userAgent,
    }
    const clickOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(trackingPayload),
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    }
    fetch("https://api.tapfiliate.com/1.6/clicks/", clickOptions)
      .then(response => response.json())
      .then(result => {
        const {id: trackingId, error} = result
        const EXPIRE_DURATION = process.env.GATSBY_TAPFILLIATE_COOKIE_DURATION
          ? JSON.parse(process.env.GATSBY_TAPFILLIATE_COOKIE_DURATION)
          : {years: 0, months: 0, days: 0, hours: 0, minutes: 5, seconds: 0}
        if (!error && EXPIRE_DURATION) {
          const customerId = nanoid(20)
          const expiresAt = add(new Date(), EXPIRE_DURATION)

          const customer = {
            status: "new",
            customer_id: customerId,
            tracking_id: customerId,
            click_id: trackingId,
            meta_data: {
              expiresAt,
            },
          }
          const customerOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(customer),
            mode: "cors",
            referrerPolicy: "origin-when-cross-origin",
          }
          let discountCode = ""
          fetch("https://api.tapfiliate.com/1.6/customers/", customerOptions)
            .then(response => response.json())
            .then(result => {
              const {id, error, status} = result
              console.log("get customer data", result)

              return id
            })
            .then(id => {
              console.log("generating discount")
              const discountOptions = {
                method: "POST",
                mode: "cors",
                body: {},
                referrerPolicy: "origin-when-cross-origin",
              }

              fetch(process.env.GATSBY_DISCOUNT_API_ENDPOINT, discountOptions)
                .then(response => response.json())
                .then(discount => {
                  const {discount_code = {}} = discount
                  console.log(discount)
                  discountCode = discount_code.code
                })
                .then(() => {
                  // ls.remove('lastCheckoutID')

                  dispatch({
                    type: trackingActions.SET_INITIAL_DATA,
                    payload: {
                      referCode,
                      customerId: id,
                      discountCode,
                      expiresAt,
                      expired: false,
                    },
                  })
                  ls("notpotTracker", {
                    referCode,
                    customerId: id,
                    discountCode,
                    expiresAt,
                    expired: false,
                  })
                })
                .catch(err => console.error(err))
            })
            .catch(e => console.error(e))
        } else {
          console.error(error)
        }
      })
      .catch(error => console.log("error", error))
  }
  useEffect(() => {
    const url = typeof window !== "undefined" ? window.location : ""
    const notpotTracker = ls("notpotTracker")
    if (url.pathname.includes("/dealer/")) {
      console.log('this is happening', affiliate)
      const {slug: referCode} = affiliate;
      if (!notpotTracker) {
        console.log("affiliate cookie does not exit need to create one")
        console.log("create click id")
        generateReferCode(referCode, url.href)
      } else if (!notpotTracker.expired) {
        console.log(referCode)
        console.log(
          "notpot cookie exist, check if affiliate same as stored in cookie"
        )
        const {referCode: cookieReferCode, email = ""} = ls("notpotTracker")
        if (!email) {
          if (referCode === cookieReferCode) {
            console.log("refer cookie same doing nothing")
          } else {
            console.log("refer cookie need to be updated")
            generateReferCode(referCode, url.href)
          }
        } else {
          console.log("ignoring this since user already converted")
        }
      }
    } else {
      console.log('crap')
    }
  }, [])

  return (
    <Div
      p={{
        t: {xs: "1.875rem", lg: "2.5rem"},
        b: {xs: "0", lg: "2.25rem"},
      }}
    >
      <WebsiteContainer>
        <Div className="dealer-container">
          <Div flexGrow="1" m={{r: {lg: "50px"}}}>
            <PolaroidCard affiliate={affiliate}/>
            <DealerDetails
              preferences={affiliate.preferences || {}}
              createdAt={affiliate.createdAt}
            />
            <Div d={{xs: "none", lg: "block"}}>
              <DealerContact
                dealerName={affiliate.name}
                socialLinks={affiliate.social || {}}
              />
            </Div>
          </Div>
          <Div flexGrow="1">
            <TopPicks
              header={affiliate.header}
              affiliatedProducts={affiliate.AffiliateProducts}
            />
          </Div>
          <Div d={{xs: "block", lg: "none"}}>
            <DealerContact
              dealerName={affiliate.name}
              socialLinks={affiliate.social || {}}
            />
          </Div>
        </Div>
      </WebsiteContainer>
    </Div>
  )
}
